const GOOGLE = {
  // client_id: '1081737795344-74fi72199rjectrm4aiqjc7rcuqkqao3'
  client_id:
    '474223898279-p0q0nb65vs2kf99tpji15oa10irvaa34.apps.googleusercontent.com'
}

const FACEBOOK = {
  appId: '165513171160130'
}

module.exports = {
  GOOGLE,
  FACEBOOK
}
